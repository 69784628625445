import '../../stylesheets/App.css';
import '../../stylesheets/StaticCard.css';
import React, {useRef, useEffect, useState} from 'react';
import StaticCard from '../StaticCard';
import {videoSettings} from '../../helpers/helperVideo';


function Future2(props) {

    useEffect(()=>{

      videoSettings('#future2-video', '.canvas.canvas-future2');
  
        }, []);

   return (
    <>
        <section ref={props.futureRef} className="panel future1-panel future2-panel flex">
            <div className="future1-section1 future2-card-container"  style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/future2/bg-card1.png)"}}>
                <StaticCard 
                title="The Future Faces of Ocean Science" 
                subtitle="SOI’s Interns" 
                description="Each year, Schmidt Ocean Institute supports science communication and data interns to foster the next generation of ocean stewards. We recently touched base with our former interns for an update on their careers since finishing their internship with SOI, and are proud of all they have accomplished." 
                image_url="future2/future2-card1.webp" 
                route="/the-future-faces-of-ocean-science"
                />
            </div>
            <div className='future2-vid-container' id="future2-vid-container">
                <video playsInline muted="muted" loop="loop" autoplay="autoplay" poster={process.env.PUBLIC_URL+"/images/future2/poster_video.png"} id="future2-video">
                    <source src={process.env.PUBLIC_URL+"/videos/Octopus.webm"} type="video/webm"/>
                    <source src={process.env.PUBLIC_URL+"/videos/Octopus.mov"} type="video/quicktime"/>
                    <p>Your browser cannot play the provided video file.</p>
                </video>
                <canvas className="canvas canvas-future2" playsInline></canvas>
            </div>    
            <div className="future1-section1 future2-card-container future2-card2-container"  style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/future2/bg-card2.png)"}}>
                <StaticCard 
                title="A Limitless Ocean of Data" 
                subtitle="SOI’s Work with Data" 
                description="Schmidt Ocean Institute (SOI) is committed to collecting and disseminating high-quality oceanographic and atmospheric data. Read more to learn about SOI’s plans for improving data access." 
                image_url="future2/future2-card2.webp" 
                route="/a-limitless-ocean-of-data"
                />
            </div>   

            <div className="timeline-container" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/home/timeline.png)"}}>   
               
            </div>

        </section>

    </>
  );
}

export default Future2;