import '../../../stylesheets/SubPage.css';
import React, {useRef, useEffect} from 'react';

function TenYearsMap() {


   return (
    <>
    <div className="subpage-recuadro general-text">
            <p>Schmidt Ocean Institute (SOI) released its 10-year Expeditions Map in January 2023, highlighting where R/V <span className="falkor-format">Falkor (too)</span> will conduct research over the next ten years. SOI will work in waters around all seven continents focusing on one geographical region each year, exploring and answering critical questions about the Ocean as outlined in our <a className="link-int-page" href="https://strategicframework.schmidtocean.org/" target="_blank">Strategic Framework</a>. With this global view, our aim is to  provide access to the Ocean for regional scientists and institutions and build community relationships in the areas closest to the science expeditions. The regions for the next decade are as follows:</p>
            <ul>
              <li><span className="text-bold">2023</span> - Atlantic, Caribbean, and Eastern Tropical Pacific</li>
              <li><span className="text-bold">2024</span> - South East Pacific and the Southern Ocean</li>
              <li><span className="text-bold">2025</span> - Southern Ocean and South West Atlantic</li>
              <li><span className="text-bold">2026</span> - South West and Tropical Atlantic and South West Caribbean</li>
              <li><span className="text-bold">2027</span> - South East and Tropical Atlantic</li>
              <li><span className="text-bold">2028</span> - South East Atlantic and Southern Ocean</li>
              <li><span className="text-bold">2029</span> - Southern Ocean and South East Indian Ocean</li>
              <li><span className="text-bold">2030</span> - South East and Central Indian Ocean</li>
              <li><span className="text-bold">2031</span> - West Indian Ocean</li>
              <li><span className="text-bold">2032</span> - South West Indian Ocean and Southern Ocean</li>
            </ul>
            <img className="full" src={process.env.PUBLIC_URL+"/images/subpages/internas/ten-years-rec1.webp"} alt="img"/>
    </div>
        
    </>
  );
}

export default TenYearsMap;