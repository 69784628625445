import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Celebrating() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/celebrating-rec1.webp"
          }
          alt="img"
        />
        <p>
          Prior to our new research vessel’s first voyage, Schmidt Ocean
          Institute (SOI) co-founder Wendy Schmidt, along with SOI staff,
          Advisory Board, crew and contractors, and members from the Freire
          Shipyard, including the Freire family, as well as representatives from
          various Shipyard contractors, gathered for a ceremony to formally
          rename the vessel as{" "}
          <span className="falkor-format">Falkor (too)</span>.
        </p>

        <p>
          The ceremony celebrated the immense effort it took to transform the
          former <span className="falkor-format">Polar Queen</span>, an offshore
          industry vessel, into{" "}
          <span className="falkor-format">Falkor (too)</span>, a platform for
          cutting-edge scientific research. Remarks were made on behalf of SOI
          by Dr. Jyotika Virmani, Executive Director, Mr. Andrew Boyer, Director
          of Engineering, and Captain Peter Reynolds. Mr. Guillermo Friere and
          Mr. D. Marcos Friere spoke on behalf of the Shipyard and presented
          Wendy Schmidt with a model of the vessel.
        </p>
        <p>
          The pinnacle of the ceremony was remarks provided by co-founders Dr. Eric
          Schmidt and Wendy Schmidt. Dr. Eric Schmidt, who presented virtual
          remarks, focused on the breakthrough technology onboard, the longevity
          of the vessel, and making history with the scientists and crew
          onboard. Wendy Schmidt then took the stage and read from{" "}
          <span className="falkor-format">The Neverending Story</span>,
          reminding those present of R/V{" "}
          <span className="falkor-format">Falkor (too)</span>’s ultimate mission
          to protect and serve the scientists and crew on their quest to better
          understand the Ocean. The ceremony also included a local orchestra who
          played the National Anthem of Spain, where the refit took place, the
          National Song of Cayman Islands, where R/V{" "}
          <span className="falkor-format">Falkor (too)</span> is flagged, and
          the National Anthem of the United States of America, where SOI is
          based. Quayside, Priest D. Jose Alberto Montes Rajoy blessed the ship
          with ceremonial words, and Wendy Schmidt christened the vessel, R/V{" "}
          <span className="falkor-format">Falkor (too)</span>, by releasing a
          bottle of sparkling wine against its bow, an ancient tradition to
          bring safety to future voyages. After the ceremony was complete, and
          while the audience cheered, members of the local Orchestra struck up a
          stirring rendition of the theme song from the 1984 film,{" "}
          <span className="falkor-format">The Neverending Story</span>.
        </p>
      </div>
    </>
  );
}

export default Celebrating;
