import '../../stylesheets/App.css';
import '../../stylesheets/SubPage.css';
import React, {useRef, useEffect} from 'react';
import RelatedGroup from './RelatedGroup';
import { goHome } from '../../helpers/helperNavigation';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

function SubPage({group="", title, subtitle="", image_url, bg_url, children}) {

  const navigate = useNavigate();
  if(window.location.pathname.includes('grants')&&window.innerWidth<=1200){
    image_url = 'grants-img-v2.webp';
  }
  window.setTimeout(function() {
    window.scrollTo(0, 0);
  }, 100);

  
 useEffect(() => {
  window.setTimeout(function() {
    window.scrollTo(0, 0);
    if(window.innerWidth<1200 && window.location.pathname.includes('rv-falkor-too')){
      document.querySelector('.subpage-header').classList.add('bg-position-right');
    }else{
      document.querySelector('.subpage-header').classList.remove('bg-position-right');
    }
}, 100);
  
}, [window.location.pathname]);




   return (
    <>
    <div className={title==="Celebrating a Seaworthy R/V Falkor (too)"?"container subpage-container bg-settings celebrating-banner":"container subpage-container bg-settings"} style={window.innerWidth<=1200?{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/subpages/bg/"+bg_url+")"}:{}}>
        <div className="subpage-header" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/subpages/banners/"+image_url+")"}}>
          
        </div>
        <div className="subpage-content bg-settings" style={window.innerWidth>1200?{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/subpages/bg/"+bg_url+")"}:{}}>
        <div className="subpage-title-container">

        {title==="Celebrating a Seaworthy R/V Falkor (too)"?
          <h1 className="subpage-title">Celebrating a Seaworthy<br/>R/V <span className="falkor-format">Falkor (too)</span></h1>:
          title==="R/V Falkor (too)"?
          <h1 className="subpage-title">R/V <span className="falkor-format">Falkor (too)</span></h1>:
          <h1 className="subpage-title">{title}</h1>
        }

        {subtitle!=''?<h2 className="subpage-subtitle">{subtitle=="A Note From Our Founders Eric and Wendy Schmidt"?"A Note From Our Founders\nEric and Wendy Schmidt":subtitle}</h2>:''}
        </div>
          <div className="general-text go-back-btn text-bold" onClick={()=>goHome([],navigate)}><FontAwesomeIcon icon={faAngleLeft} />Home</div>
          {children}
          <RelatedGroup group={group} title={title}/>
        </div>
    </div>
        
    </>
  );
}

export default SubPage;