import '../stylesheets/App.css';
import '../stylesheets/Card.css';
import React, {useRef, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { openSubPage } from '../helpers/helperNavigation';

gsap.registerPlugin(ScrollTrigger);



function NewCard(props) {
  const navigate = useNavigate();

   return (
    <>
        <div className="static-card-img bg-settings" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/"+props.image_url+")"}}
        id={'card-'+props.title.trim().split(" ")[0]}/>
        <div className="static-card-title-container">
            <h1 className="title">
            {props.title=="The Future Faces of Ocean Science"?"The Future Faces \nof Ocean Science":props.title}
            </h1>
            <h2 className="subtitle">
            {props.subtitle=="A Note From Our Founders Eric and Wendy Schmidt"?"A Note From Our Founders\nEric and Wendy Schmidt":props.subtitle}
            </h2>
            
            {props.description=="The past year was centered on transformation as we bid farewell to R/V Falkor in Italy—where she is now R/V Gaia Blu with the Consiglio Nazionale delle Ricerche—and watched R/V Falkor (too) complete its metamorphosis."?
            <p className="general-text">The past year was centered on transformation as we bid farewell to R/V <span className="falkor-format">Falkor</span> in Italy—where she is now R/V <span className="falkor-format">Gaia Blu</span> with the Consiglio Nazionale delle Ricerche—and watched R/V <span className="falkor-format">Falkor (too)</span> complete its metamorphosis. </p>:
            <p className="general-text">{props.description}</p>}
           
            <img className="enter-arrow"  onClick={()=>openSubPage(navigate, props.route)}
            src={process.env.PUBLIC_URL+"/images/future1/flecha_enter.png"} alt="enter"/>
        </div>
    </>
  );
}

export default NewCard;