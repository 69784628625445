import '../stylesheets/App.css';
import '../stylesheets/Card.css';
import React, {useRef, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { openSubPage } from '../helpers/helperNavigation';

gsap.registerPlugin(ScrollTrigger);



function NewCard(props) {

    const navigate = useNavigate();
    const imgCard = useRef(null);
    const titleCard = useRef(null);
    const containerCard = useRef(null);

    /*revision de los titulos posibles y como acomodarlos*/
    let title = props.title;
    let falkorTitle='';
    switch(props.title){
        case 'Expedition Map':
            title= "Expedition \nMap";
            break;
        case 'New Proposal Process':
            title = "New Proposal \nProcess";
            break;
        case 'Celebrating R/V Falkor (too)':
            title = "Celebrating \nR/V ";
            break;
        case 'R/V Falkor (too)':
            title = "R/V \n"
    }
    if(props.title.includes('Falkor (too)')){
        //console.log('contiene falkor');
        falkorTitle = 'Falkor (too)';
    }


   return (
    <>
        <div className="card-container" onClick={()=>openSubPage(navigate, props.route)} 
        ref={containerCard}  style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/card/bg-card-blue.png)"}}>
            <div className="card-image-container">
                <div className="card-image" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/card/"+props.image_url}} ref={imgCard}></div>
            </div>
            <div className="card-title-container">
                <h1 className="card-title" ref={titleCard}>{title}<span className="falkor-format">{falkorTitle}</span></h1>
                <img className="enter-arrow" src={process.env.PUBLIC_URL+"/images/card/arrow_enter_white.png"} alt="enter"/>
            </div>
        </div>
    </>
  );
}

export default NewCard;