import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function DirectorsNote() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/directors-note-rec1.webp"
          }
          alt="img"
        />
        <p>
          The past year was centered on transformation as we bid farewell to R/V{" "}
          <span className="falkor-format">Falkor</span> in Italy—where she is
          now R/V <span className="falkor-format">Gaia Blu</span> with the
          Consiglio Nazionale delle Ricerche—and watched R/V{" "}
          <span className="falkor-format">Falkor (too)</span> complete its
          metamorphosis. The ship, formerly known as the{" "}
          <span className="falkor-format">Polar Queen</span>, transformed from a
          platform designed for the offshore industry, installing wind farms in
          northern Europe, to a state-of-the-art research vessel. The refit took
          place in Vigo, Spain, at the Freire Shipyard—the same shipyard that
          designed and built the{" "}
          <span className="falkor-format">Polar Queen</span> in 2011. We were
          all kept up-to-date during the refit with an incredible ten-part video
          series,{" "}
          <a
            className="link-int-page"
            href="https://youtube.com/playlist?list=PLJGVqQI3okzbArZ1orJXmhdB8zrmIWkuZ"
            target="_blank"
          >
            The Making of a Research Ship
          </a>
          , which, amongst other things, captured the installation of the
          19-meter gondola under the hull, the conversion of an entire deck from
          cabins into eight state-of-the-art lab spaces, and the first complete
          overhaul of ROV <span className="falkor-format">SuBastian</span> since
          it was built in 2015. There were trials and tribulations but also
          great triumphs. R/V{" "}
          <span className="falkor-format">Falkor (too)</span> finally
          materialized as a global-class Ocean research vessel due to the
          extraordinary efforts of our dedicated crew, operations team,
          contractors, and the Shipyard. As the year came to a close, we
          celebrated a completed R/V{" "}
          <span className="falkor-format">Falkor (too)</span> and the
          continuation of our never-ending journey with a new ship dedicated to
          science and Ocean exploration.
        </p>
        <p>
          Transformation also occurred across Schmidt Ocean Institute as we
          planned for the next ten years, guided by our{" "}
          <a
            className="link-int-page"
            href="https://strategicframework.schmidtocean.org/?_ga=2.134381108.437943452.1681864137-542219080.1620776639"
            target="_blank"
          >
            Strategic Framework
          </a>
          . This included an updated proposal process for ship time and the
          release of our 10-year Expedition Map for{" "}
          <span className="falkor-format">Falkor (too)</span>, which illustrates
          the regions around the world where we will operate over the next ten
          years–primarily in the Southern Hemisphere. We announced a new
          partnership in June with the{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/soi-ngs-partner-to-advance-diversity/"
            target="_blank"
          >
            National Geographic Society
          </a>{" "}
          at the High-Level Meeting of the Ocean Decade Alliance at the UN
          Oceans Conference in Lisbon, Portugal. Throughout the year, we also
          continued our ongoing collaborations with existing partners like
          Nekton on our joint{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/nekton-and-schmidt-ocean-institute-kick-off-joint-quest-to-inspire-the-public-about-the-ocean/"
            target="_blank"
          >
            Ocean Rising initiative
          </a>
          .
        </p>
        <p>
          I hope you will enjoy the look back on this past year and the forward
          momentum outlined within our ten-year reflective Impact Report. We
          anticipate the coming year to be a game-changing one as we advance our
          quest to boldly explore our unknown Ocean and better understand its
          mysteries.{" "}
        </p>
      </div>
    </>
  );
}

export default DirectorsNote;
