import {Link, useNavigate} from 'react-router-dom';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { TweenMax } from "gsap";

gsap.registerPlugin(ScrollTrigger);

export function openSubPage(navigate, route) { //imageId
  /*
    let image = document.getElementById(imageId);
    console.log(image.getBoundingClientRect().top);
    let background;
    if (image.tagName === 'DIV') {
        background = image.style.backgroundImage;
      } else if (image.tagName === 'IMG') {
        background = "url("+image.src+")";
      }
    
    //creo nueva imagen
    let imgFull = document.createElement("div");
    imgFull.classList.add("img-full-transition");
    
    imgFull.style.backgroundImage = background;
    imgFull.style.top = image.getBoundingClientRect().top+"px";
    imgFull.style.left = image.getBoundingClientRect().left+"px";
    imgFull.style.width = image.getBoundingClientRect().width+"px";
    imgFull.style.height = image.getBoundingClientRect().height+"px";
    imgFull.style.display = 'initial';
    document.querySelector("#root").append(imgFull);
    console.log(imgFull);
    
    gsap.to(imgFull, {
    width:'100vw', 
    height: '100vh',
    top:0,
    left:0,
    duration:.7
    })
    console.log(imgFull);
    
    setTimeout(()=>{navigate(route);imgFull.style.display='none'}, 1000);
    */
    gsap.fromTo(
        // Elemento o elementos a animar
        '#root',
        // Configuración de animación inicial
        { opacity: 1 },
        // Configuración de animación final
        { opacity: 0, duration: .5, ease: 'power2.out' }
      );
    setTimeout(()=>navigate(route), 500);

}

export function goHome(props, navigate) {
    if(window.location.pathname=="/"){
        let puntoMedio = getMiddlePoint();
        window.scroll(0,puntoMedio);
    }else{
        navigate("/")
    }
  }

export function goHomeFromMenu(props, navigate) {
    props.updateMenu();
    if(window.location.pathname=="/"){
        let puntoMedio = getMiddlePoint();
        window.scroll(0,puntoMedio);
    }else{
        navigate("/")
    }
  }


export function getMiddlePoint(){
    return window.innerWidth>1200?((document.querySelector(".container").clientWidth*4/7)+(window.innerWidth*0.667)): document.getElementById("center-panel").offsetTop;
}

export function goToSubPageFromMenu(route, navigate, props) {
    props.updateMenu();
    navigate(route);
}

export function scrollToSection(sectionRef, navigate, props, offset=0){
    props.updateMenu();
    if(window.innerWidth>1200){
        if(window.location.pathname!="/"){
          navigate("/");
          setTimeout(()=>{
            desktopScroll(sectionRef, offset);
          }, 5000);
        }else{
          desktopScroll(sectionRef, offset);
        }
    }else{
      if(window.location.pathname!="/"){
        navigate("/");
        setTimeout(()=>{
          mobileScroll(sectionRef, offset);
        }, 5000);
      }else{
        mobileScroll(sectionRef, offset);
      }
    }
    

    
}

function desktopScroll(sectionRef, offset){
  if (sectionRef.current) {
    let aux =sectionRef.current.getBoundingClientRect().left/6;

    const sectionPositionLeft = sectionRef.current.getBoundingClientRect().left+offset;
    console.log(sectionPositionLeft)
    window.scrollBy({
      top: sectionPositionLeft+aux,
      behavior: 'smooth',
    });
    /*
    setTimeout(()=>{
      if(Math.abs((sectionRef.current.getBoundingClientRect().left+offset))>10){
        window.scrollBy({
        top: sectionRef.current.getBoundingClientRect().left+offset,
        behavior: 'smooth',
        });

      } 
    }
      ,1000);
    setTimeout(()=>{
      if(Math.abs((sectionRef.current.getBoundingClientRect().left+offset))>10){
        window.scrollBy({
        top: sectionRef.current.getBoundingClientRect().left+offset,
        behavior: 'smooth',
        });

      } 
    }

      ,2000);
      */
    
  }
}

function mobileScroll(sectionRef, offset){
  if (sectionRef.current) {
    const sectionPositionTop = sectionRef.current.getBoundingClientRect().top;
    window.scrollBy({
      top: sectionPositionTop-70,
      behavior: 'smooth',
    });
  }
}



/*SECCIONES Y SUS REF:
disseminatingRef
deepSeaRef
anthologyRef
byTheNumbersRef
reflectionsRef /CREATE
centerRef 
visionsRef /CREATE
soiRef
futureRef 
            */
function getSectionLimits(props){
  let limits;
  if(window.innerWidth>1200){
    limits = [
      props.disseminatingRef.current.getBoundingClientRect().left+window.scrollY,
      props.deepSeaRef.current.getBoundingClientRect().left+window.scrollY-235,
      props.anthologyRef.current.getBoundingClientRect().left+window.scrollY-235,
      props.byTheNumbersRef.current.getBoundingClientRect().left+window.scrollY-100,
      props.reflectionsRef.current.getBoundingClientRect().left+window.scrollY-450,
      props.centerRef.current.getBoundingClientRect().left+window.scrollY-100,
      props.visionsRef.current.getBoundingClientRect().left+window.scrollY-450,
      props.soiRef.current.getBoundingClientRect().left+window.scrollY-435,
      props.futureRef.current.getBoundingClientRect().left+window.scrollY-235
    ]
  }else{
    limits = [
      props.disseminatingRef.current.getBoundingClientRect().top,
      props.deepSeaRef.current.getBoundingClientRect().top-120,
      props.anthologyRef.current.getBoundingClientRect().top-120,
      props.byTheNumbersRef.current.getBoundingClientRect().top-120,
      props.reflectionsRef.current.getBoundingClientRect().top-120,
      props.centerRef.current.getBoundingClientRect().top-120,
      props.visionsRef.current.getBoundingClientRect().top-120,
      props.soiRef.current.getBoundingClientRect().top-120,
      props.futureRef.current.getBoundingClientRect().top
    ]
  }
  return limits;
}


export function goToPrevSection(props){

  let actualPosition = window.scrollY;
  console.log(actualPosition)
  let sectionRef;
  let offset = -120;
  let limits = getSectionLimits(props);

  if(actualPosition > limits[8]){
    sectionRef = props.soiRef;
    offset = -400;
    console.log('go to soi');
  }else if(actualPosition > limits[7] && actualPosition < limits[8]){
    sectionRef = props.visionsRef;
    offset = -400;
    console.log('go to visions');
  } else if(actualPosition > limits[6] && actualPosition < limits[7]){
    sectionRef = props.centerRef;
    offset = 0;
    console.log('go to center');
  } else if(actualPosition > limits[5] && actualPosition < limits[6]){
    sectionRef = props.reflectionsRef;
    offset = -400;
    console.log('go to reflections');
  } else if(actualPosition > limits[4] && actualPosition < limits[5]){
    sectionRef = props.byTheNumbersRef;
    offset = 0;
    console.log('go to by the numbers');
  }else if(actualPosition > limits[3] && actualPosition < limits[4]){
    sectionRef = props.anthologyRef;
    console.log('go to anthology');
  }else if(actualPosition > limits[2] && actualPosition < limits[3]){
    sectionRef = props.deepSeaRef;
    console.log('go to deepSea');
  }else if(actualPosition > limits[1] && actualPosition < limits[2]){
    sectionRef = props.disseminatingRef;
    console.log('go to disseminating');
    offset = 0;
  }else{
    console.log('no entra en ninguno');
  }
  if(window.innerWidth>1200){
    desktopScroll(sectionRef, offset);
  }else{
    mobileScroll(sectionRef, offset);
  }
}

export function goToNextSection(props){

  let actualPosition = window.scrollY;
  console.log(actualPosition)
  let sectionRef;
  let offset = -120;
  let limits = getSectionLimits(props);
  if (actualPosition >= limits[0] && actualPosition < limits[1]) {
    sectionRef = props.deepSeaRef;
    console.log('go to deep sea');
  } else if (actualPosition > limits[1] && actualPosition < limits[2]) {
    sectionRef = props.anthologyRef;
    console.log('go to anthology');
  } else if (actualPosition > limits[2] && actualPosition < limits[3]) {
    sectionRef = props.byTheNumbersRef;
    offset = 0;
    console.log('go to numbers');
  } else if (actualPosition > limits[3] && actualPosition < limits[4]) {
    sectionRef = props.reflectionsRef;
    offset = -400;
    console.log('go to reflections');
  } else if (actualPosition > limits[4] && actualPosition < limits[5]) {
    sectionRef = props.centerRef;
    offset = 0;
    console.log('go to center');
  } else if (actualPosition > limits[5] && actualPosition < limits[6]) {
    sectionRef = props.visionsRef;
    offset = -400;
    console.log('go to visions');
  } else if (actualPosition > limits[6] && actualPosition < limits[7]) {
    sectionRef = props.soiRef;
    offset = -400;
    console.log('go to SOI');
  } else if (actualPosition > limits[7]) {
    sectionRef = props.futureRef;
    offset = 0;
    console.log('go to future');
  } else {
    console.log('no entra en ninguno');
  }
  
  if(window.innerWidth>1200){
    desktopScroll(sectionRef, offset);
  }else{
    mobileScroll(sectionRef, offset);
  }


}

export function scrollToFacesSection(sectionRef, navigate, props){
  props.updateMenu();
  if(window.location.pathname!="/the-future-faces-of-ocean-science"){
    navigate("/the-future-faces-of-ocean-science");
    setTimeout(()=>{
      facesScroll();
    }, 1000);
  }else{
    setTimeout(()=>{
      facesScroll();
    }, 500);
  }


function facesScroll(){
  if (sectionRef.current) {
    const sectionPositionTop = window.innerWidth>1200?
    sectionRef.current.getBoundingClientRect().top-(window.innerWidth*0.1):
    sectionRef.current.getBoundingClientRect().top-100;
    window.scrollBy({
      top: sectionPositionTop,
      behavior: 'smooth',
    });
    
    setTimeout(()=>{
      gsap.fromTo(
        // Elemento o elementos a animar
        sectionRef.current,
        // Configuración de animación inicial
        { transform:'scale(1)' },
        // Configuración de animación final
        { transform:'scale(1.1)', duration: .5, ease: 'power2.out' }
      );
      let senial = false;
      document.addEventListener('scroll', function(){
        while(senial==false){
          senial=true;
          gsap.fromTo(
            // Elemento o elementos a animar
            sectionRef.current,
            // Configuración de animación inicial
            { transform:'scale(1.1)' },
            // Configuración de animación final
            { transform:'scale(1)', duration: .5, ease: 'power2.out' }
          );
        }
        
      })
    }, 1000)

  }
}
}