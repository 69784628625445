import '../stylesheets/App.css';
import '../stylesheets/Header.css';
import React, {useRef, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLinesLeaning } from '@fortawesome/free-solid-svg-icons';
import Menu from './Menu';
import { goHome } from '../helpers/helperNavigation';

function Header(props) {

    let [actualScroll, setActualScroll] = useState(0);
    let [styleObject, setStyleObject] = useState({});
    let navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("scroll", ()=>{
            
            console.log("y: "+window.scrollY)
            console.log("x: "+window.scrollX)
            console.log("Ancho del contenedor:"+document.querySelector(".container").clientWidth)
            console.log("alto del container : "+document.querySelector(".container").clientHeight)
            window.innerWidth>1200 && window.location.pathname=="/"?
            setActualScroll(window.scrollY*100/document.querySelector(".container").clientWidth):
            setActualScroll(window.scrollY*100/(document.querySelector(".container").clientHeight-window.innerHeight));
            /*
            console.log(window.scrollY*100/(document.querySelector(".container").clientHeight-window.innerHeight));
            console.log(window.innerHeight)
            console.log(window.location.pathname);*/
        })
      }, []);

      useEffect(() => {
        if(window.innerWidth>1200){
            setStyleObject({height:actualScroll+"%"})
        }else{
            setStyleObject({width:actualScroll+"%"})
            console.log("alto del container : "+document.querySelector(".container").clientHeight)
        }
      }, [actualScroll]);
    

   return (
    <>
        <div className="nav">
            <div className="principal-nav">
                <div className="toggle-icon-container" onClick={()=>goHome(props, navigate)}>
                    <img className="nav-logo" src={process.env.PUBLIC_URL+"/images/home/logo_parte2.png"}  alt="soi-logo"/>
                </div>
                <div className="toggle-icon-container toggle" onClick={()=> props.updateMenu()}>
                <FontAwesomeIcon icon={faBars} />
                </div>
                <div className="logo-container">
                    <h1>Impact Report</h1>
                </div>
                <div className="year-container">
                    <h1>2022</h1>
                </div>
            </div>
            <div className="progress-nav-container">
                <div className="progress-nav" style={styleObject}/>
            </div>
            
        </div>
    </>
  );
}

export default Header;