import '../../stylesheets/App.css';
import React, {useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { openSubPage } from '../../helpers/helperNavigation';

function Past2(props) {

  const navigate = useNavigate();
    
   return (
    <>
        <section   ref={props.anthologyRef} className="panel past2-panel flex" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past2/bg-past2.png)"}}>
          <div className="title-container mt-general">
            <h1 className="title">A Scientific Anthology</h1>
            <h2 className="subtitle">Expeditions from the Past Ten Years</h2>
          </div>
            
          <div className="past2-flex-container">
            <div className="past2-item general-text">
            Take a look back at some of Schmidt Ocean Institute’s expeditions from the past ten years  and what they accomplished in support of our mission to catalyze the discoveries needed to understand our Ocean, sustain life, and ensure the health of our planet.
            </div>
            <div className="past2-item past2-card" onClick={()=>openSubPage(navigate, '/mapping-the-seafloor')}
            style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past2/past2-card-bg.png)"}}>
              <img src={process.env.PUBLIC_URL+"/images/past2/past2-item1.png"}  alt="icon"/>
              <h3 className="card-title">Mapping<br/>the Seafloor</h3>
            </div>
            <div className="past2-item past2-card" onClick={()=>openSubPage(navigate, '/exploring-the-deep')}
             style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past2/past2-card-bg.png)"}}>
              <img src={process.env.PUBLIC_URL+"/images/past2/past2-item2.png"} alt="icon"/>
              <h3 className="card-title">Exploring <br/>the Deep</h3>
            </div>
            <div className="past2-item past2-card" onClick={()=>openSubPage(navigate, '/frontiers-of-biodiversity')}
             style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past2/past2-card-bg.png)"}}>
              <img src={process.env.PUBLIC_URL+"/images/past2/past2-item3.png"}  alt="icon"/>
              <h3 className="card-title">Frontiers<br/>of Biodiversity</h3>
            </div>
            <div className="past2-item past2-card" onClick={()=>openSubPage(navigate, '/technologies-for-the-future')}
             style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past2/past2-card-bg.png)"}}>
              <img src={process.env.PUBLIC_URL+"/images/past2/past2-item4.png"}  alt="icon"/>
              <h3 className="card-title">Technologies <br/>for the Future</h3>
            </div>
            <div className="past2-item past2-card" onClick={()=>openSubPage(navigate, '/stability-of-ocean-health')}
             style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past2/past2-card-bg.png)"}}>
              <img src={process.env.PUBLIC_URL+"/images/past2/past2-item5.png"}  alt="icon"/>
              <h3 className="card-title">Stability of <br/>Ocean Health</h3>
            </div>
            <div className="past2-item past2-card" onClick={()=>openSubPage(navigate, '/the-human-effect')}
             style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past2/past2-card-bg.png)"}}>
              <img src={process.env.PUBLIC_URL+"/images/past2/past2-item6.png"}  alt="icon"/>
              <h3 className="card-title">The <br/>Human Effect</h3>
            </div>
            <div className="past2-item past2-card" onClick={()=>openSubPage(navigate, '/power-of-nature')}
             style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past2/past2-card-bg.png)"}}>
              <img src={process.env.PUBLIC_URL+"/images/past2/past2-item7.png"}  alt="icon"/>
              <h3 className="card-title">Power<br/>of Nature</h3>
            </div>
          </div>
        </section>

    </>
  );
}

export default Past2;