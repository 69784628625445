import '../stylesheets/App.css';
import '../stylesheets/Card.css';
import React, {useRef, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { openSubPage } from '../helpers/helperNavigation';

gsap.registerPlugin(ScrollTrigger);



function BlueCard(props) {

    const navigate = useNavigate();
    
   return (
    <>
        <div className="card-container"  onClick={()=>openSubPage(navigate, props.route)}
        style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/card/bg-card-blue.png)"}}>
            <div className="card-image-container">
                <div className="card-image" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/card/"+props.image_url}}></div>
            </div>
            <div className="card-title-container">
                <h1 className="card-title">{props.title}</h1>
                <img className="enter-arrow" src={process.env.PUBLIC_URL+"/images/card/arrow_enter_white.png"} alt="arrow"/>
            </div>
        </div>
    </>
  );
}

export default BlueCard;