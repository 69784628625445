import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Power() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL + "/images/subpages/internas/power-rec1.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/tracking-the-tasman-seas-hidden-tide/"
            target="_blank"
          >
            Tracking the Tasman Sea’s Hidden Tides
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Amy Waterhouse, University of California San
          Diego, Scripps Institution of Oceanography
        </h4>
        <p>
          In 2015, for the first time in R/V {" "}
          <span className="falkor-format">Falkor’</span>s history of operations,
          R/V <span className="falkor-format">Falkor</span> worked alongside
          another vessel, the R/V{" "}
          <span className="falkor-format">Roger Revelle</span>, to conduct a
          comprehensive study of the Tasman Sea internal tide beam.{" "}
          <a
            className="link-int-page"
            href="https://journals.ametsoc.org/view/journals/phoc/48/6/jpo-d-17-0116.1.xml"
            target="_blank"
          >
            General observations of this tidal beam
          </a>
          , a regularly occurring internal wave produced by tides, and its
          interactions in the Tasman Sea were first published by the science
          team in 2018. Researchers subsequently used the data collected from
          the Tasman Sea to understand how these internal waves interact with{" "}
          <a
            className="link-int-page"
            href="https://journals.ametsoc.org/view/journals/phoc/50/10/jpoD190283.xml?tab_body=fulltext-display"
            target="_blank"
          >
            background active flows
          </a>{" "}
          to{" "}
          <a
            className="link-int-page"
            href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2020GL091692"
            target="_blank"
          >
            inform a new type of global reduced-physics model
          </a>
          . An improved model will lead to a better understanding of the
          generation, propagation, and dissipation of these waves, which are
          challenging to accurately observe and forecast at a global scale.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL + "/images/subpages/internas/power-rec2.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/studying-the-sea-surface-microlayer-2/"
            target="_blank"
          >
            Studying the Sea-Surface Microlayer 2
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Christopher Zappa, Lamont-Doherty Earth
          Observatory - Columbia University
        </h4>
        <p>
          Building upon the{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/study-of-the-sea-surface-microlayer/"
            target="_blank"
          >
            first study of the sea-surface microlayer on board R/V{" "}
            <span className="falkor-format">Falkor</span> in 2016
          </a>
          , a team of scientists and engineers conducted unmanned aerial vehicle
          (UAV) operations{" "}
          <a
            className="link-int-page"
            href="https://zenodo.org/record/7562163#.ZEKeOHbMI67"
            target="_blank"
          >
            that achieved vertical take-off and landing while the ship was in
            transit. The AUV's were equipped with highly accurate sensors that measured
            the sea-surface microlayer at high-resolution scales
          </a>
          . The sea-surface microlayer is the interface between the atmosphere
          and Ocean, thus playing an important role in Earth’s Ocean
          biogeochemical cycles and air-sea interactions. The UAVs were used to
          locate Trichodesmium blooms (harmful algae) and floating rafts of
          pumice thought to have been from an undersea eruption near Tonga. The
          main role of the UAVs and their sensors was to collect physical
          oceanographic data at the air-sea interface. Over the 250 hours of
          flight time, the three UAVs collected sea surface temperature imagery,
          surface-emitted radiance and irradiance, solar irradiance, albedo,
          turbulent momentum flux, turbulent sensible and latent heat flux and
          ocean skin temperature data, which is still being analyzed by the
          researchers at Columbia University.
        </p>
      </div>
    </>
  );
}

export default Power;
