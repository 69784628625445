import '../../stylesheets/App.css';
import '../../stylesheets/StaticCard.css';
import React, {useRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Card from '../NewCard';
import StaticCard from '../StaticCard';
import gsap from 'gsap';
import { openSubPage } from '../../helpers/helperNavigation';


function Future1(props) {

    let navigate = useNavigate();


   return (
    <>
        <section className="panel future1-panel future1-panel-card-container flex">
            <div ref={props.visionsRef} className="future1-section1"  style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/future1/bg-future1-section1.png)"}}>
                <StaticCard 
                title="Visions of the Future" 
                subtitle="A Note From Our Founders Eric and Wendy Schmidt" 
                description="For all of human history, the horizon has captured our attention, daring us to venture out to sea to find what might lie beyond, or below. The year 2023 is no different—except those who venture now have a bigger ship." 
                image_url="future1/visions-img.webp" 
                route="/words-from-our-founders"
                />
            </div>
            <div className="future1-section2"  ref={props.soiRef} style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/future1/bg-future1-section2.webp)"}} id="soi-img">
                <div className="text-container mt-general">
                    <h1 className="title future1-title text-white">SOI Remastered</h1>
                    <h2 className="subtitle future1-subtitle text-white">Procedural Updates and New Additions</h2>
                    <p className="general-text future1-description text-white">Beyond refitting and celebrating the renaming of R/V <span className="falkor-format">Falkor (too)</span>, Schmidt Ocean Institute (SOI) reviewed and updated a number of our programs and operations, including <a className="text-white text-underlined" href="https://schmidtocean.org/expression-of-interest-2023/" target="_blank">how we accept research proposals</a> and where we will conduct research in the next decade.</p>
                </div>
                <div className="cards-future1-container">
                    <Card 
                        title="Expedition Map" 
                        image_url="future1-card1.webp"
                        route="/10-year-soi-expeditions-map"/>
                    <Card 
                        title="New Proposal Process" 
                        image_url="future1-card2.webp"
                        route="/new-proposal-process"/>
                    <Card 
                        title="Celebrating R/V Falkor (too)" 
                        image_url="future1-card3.webp"
                        route="/celebrating-a-seaworthy"/>
                    <Card 
                        title="R/V Falkor (too)" 
                        image_url="future1-card4.webp"
                        route="/rv-falkor-too"/>
                </div>
            </div>
        </section>

    </>
  );
}

export default Future1;