import "../stylesheets/App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import makeVideoPlayableInline from "iphone-inline-video";

import { gsap } from "gsap";

import Home from "./Home.js";
import HomeMobile from "./HomeMobile.js";
import Header from "./Header.js";
import Menu from "./Menu.js";
import SubPage from "./subpages/SubPage.js";

import Artists from "./subpages/content/Artists";
import Awards from "./subpages/content/Awards";
import Celebrating from "./subpages/content/Celebrating";
import Deep from "./subpages/content/Deep";
import DirectorsNote from "./subpages/content/DirectorsNote";
import Frontiers from "./subpages/content/Frontiers";
import FutureFaces from "./subpages/content/FutureFaces";
import Grants from "./subpages/content/Grants";
import Human from "./subpages/content/Human";
import Leadership from "./subpages/content/Leadership";
import Limitless from "./subpages/content/Limitless";
import Museum from "./subpages/content/Museum";
import Power from "./subpages/content/Power";
import Press from "./subpages/content/Press";
import Proposal from "./subpages/content/Proposal";
import RVFalkor from "./subpages/content/RVFalkor";
import Seafloor from "./subpages/content/Seafloor";
import Stability from "./subpages/content/Stability";
import Strategic from "./subpages/content/Strategic";
import Technologies from "./subpages/content/Technologies";
import TenYearsMap from "./subpages/content/TenYearsMap";
import WordsFromOurFounders from "./subpages/content/WordsFromOurFounders";

function App() {
  let [menu, setMenu] = useState(false);
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const byTheNumbersRef = useRef(null);
  const anthologyRef = useRef(null);
  const disseminatingRef = useRef(null);
  const deepSeaRef = useRef(null);
  const centerRef = useRef(null);
  const futureRef = useRef(null);
  const soiRef = useRef(null);
  const reflectionsRef = useRef(null);
  const visionsRef = useRef(null);
  const faces1Ref = useRef(null);
  const faces2Ref = useRef(null);
  const faces3Ref = useRef(null);
  const faces4Ref = useRef(null);
  const faces5Ref = useRef(null);
  const faces6Ref = useRef(null);
  const faces7Ref = useRef(null);
  const faces8Ref = useRef(null);
  const faces9Ref = useRef(null);

  useEffect(() => {
    //codigo para probar referencia
    /*
    if (byTheNumbersRef.current) {
      const { top, left } = byTheNumbersRef.current.getBoundingClientRect();
      console.log('Posición superior:', top);
      console.log('Posición izquierda:', left);
      console.log('offsetLeft: '+ byTheNumbersRef.current.offsetLeft);
      console.log('offsetRight: '+ byTheNumbersRef.current.offsetRight);
    }*/
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newWindowWidth = window.innerWidth;

      if (newWindowWidth !== windowWidth) {
        window.location.reload();
      }

      setWindowWidth(newWindowWidth);
    };

    window.addEventListener("resize", handleResize);

    // Limpieza del event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  useEffect(() => {
    // Realiza la animación de transición cuando cambia la ubicación
    const animateTransition = () => {
      // Realiza la animación utilizando GSAP
      gsap.fromTo(
        // Elemento o elementos a animar
        "#root",
        // Configuración de animación inicial
        { opacity: 0 },
        // Configuración de animación final
        { opacity: 1, duration: 2, ease: "power2.out" }
      );
    };

    animateTransition();
  }, [location]);

  const updateMenu = () => {
    if (menu) {
      setMenu(false);
      document.querySelector("body").style.overflowY = "scroll";
    } else {
      setMenu(true);
      document.querySelector("body").style.overflowY = "hidden";
    }
  };

  return (
    <>
      {menu ? (
        <Menu
          updateMenu={updateMenu}
          byTheNumbersRef={byTheNumbersRef}
          anthologyRef={anthologyRef}
          deepSeaRef={deepSeaRef}
          disseminatingRef={disseminatingRef}
          soiRef={soiRef}
          faces1Ref={faces1Ref}
          faces2Ref={faces2Ref}
          faces3Ref={faces3Ref}
          faces4Ref={faces4Ref}
          faces5Ref={faces5Ref}
          faces6Ref={faces6Ref}
          faces7Ref={faces7Ref}
          faces8Ref={faces8Ref}
          faces9Ref={faces9Ref}
        />
      ) : (
        ""
      )}
      <Header updateMenu={updateMenu} />
      <Routes>
        <Route
          path="/directors-note"
          exact
          element={
            <SubPage
              group=""
              title="Reflections from the Past"
              subtitle="A Note From Our Executive Director"
              image_url="directors-note-img.webp"
              bg_url="directors-note-bg.png"
            >
              <DirectorsNote />
            </SubPage>
          }
        />
        <Route
          path="/mapping-the-seafloor"
          exact
          element={
            <SubPage
              group="A Scientific Anthology"
              title="Mapping the Seafloor"
              subtitle=""
              image_url="seafloor-img.webp"
              bg_url="seafloor-bg.png"
            >
              <Seafloor />
            </SubPage>
          }
        />
        <Route
          path="/the-human-effect"
          exact
          element={
            <SubPage
              group="A Scientific Anthology"
              title="The Human Effect"
              subtitle=""
              image_url="human-img.webp"
              bg_url="seafloor-bg.png"
            >
              <Human />
            </SubPage>
          }
        />
        <Route
          path="/technologies-for-the-future"
          exact
          element={
            <SubPage
              group="A Scientific Anthology"
              title="Technologies for the Future"
              subtitle=""
              image_url="technologies-img.webp"
              bg_url="seafloor-bg.png"
            >
              <Technologies />
            </SubPage>
          }
        />
        <Route
          path="/exploring-the-deep"
          exact
          element={
            <SubPage
              group="A Scientific Anthology"
              title="Exploring the Deep"
              subtitle=""
              image_url="deep-img.webp"
              bg_url="seafloor-bg.png"
            >
              <Deep />
            </SubPage>
          }
        />
        <Route
          path="/power-of-nature"
          exact
          element={
            <SubPage
              group="A Scientific Anthology"
              title="Power of Nature"
              subtitle=""
              image_url="power-img.webp"
              bg_url="seafloor-bg.png"
            >
              <Power />
            </SubPage>
          }
        />
        <Route
          path="/stability-of-ocean-health"
          exact
          element={
            <SubPage
              group="A Scientific Anthology"
              title="Stability of Ocean Health"
              subtitle=""
              image_url="stability-img.webp"
              bg_url="seafloor-bg.png"
            >
              <Stability />
            </SubPage>
          }
        />
        <Route
          path="/frontiers-of-biodiversity"
          exact
          element={
            <SubPage
              group="A Scientific Anthology"
              title="Frontiers of Biodiversity"
              subtitle=""
              image_url="frontiers-img.webp"
              bg_url="seafloor-bg.png"
            >
              <Frontiers />
            </SubPage>
          }
        />

        <Route
          path="/strategic-partnerships"
          exact
          element={
            <SubPage
              group="The Deep Sea Continuum"
              title="Strategic Partnerships"
              subtitle=""
              image_url="strategic-img.webp"
              bg_url="grants-bg.png"
            >
              <Strategic />
            </SubPage>
          }
        />
        <Route
          path="/leadership"
          exact
          element={
            <SubPage
              group="The Deep Sea Continuum"
              title="Leadership"
              subtitle=""
              image_url="leadership-img.webp"
              bg_url="grants-bg.png"
            >
              <Leadership />
            </SubPage>
          }
        />
        <Route
          path="/grants"
          exact
          element={
            <SubPage
              group="The Deep Sea Continuum"
              title="Grants"
              subtitle=""
              image_url="grants-img.webp"
              bg_url="grants-bg.png"
            >
              <Grants />
            </SubPage>
          }
        />

        <Route
          path="/artists-at-sea"
          exact
          element={
            <SubPage
              group="Disseminating the Depths"
              title="Artist-at-Sea"
              subtitle=""
              image_url="artists-img.webp"
              bg_url="artists-bg.png"
            >
              <Artists />
            </SubPage>
          }
        />
        <Route
          path="/awards"
          exact
          element={
            <SubPage
              group="Disseminating the Depths"
              title="Awards"
              subtitle=""
              image_url="awards-img.webp"
              bg_url="artists-bg.png"
            >
              <Awards />
            </SubPage>
          }
        />
        <Route
          path="/museum-exhibits"
          exact
          element={
            <SubPage
              group="Disseminating the Depths"
              title="Museum Exhibits"
              subtitle=""
              image_url="museum-img.webp"
              bg_url="artists-bg.png"
            >
              <Museum />
            </SubPage>
          }
        />
        <Route
          path="/press-and-footage"
          exact
          element={
            <SubPage
              group="Disseminating the Depths"
              title="Press and Footage"
              subtitle=""
              image_url="press-img.webp"
              bg_url="artists-bg.png"
            >
              <Press />
            </SubPage>
          }
        />

        <Route
          path="/words-from-our-founders"
          exact
          element={
            <SubPage
              group=""
              title="Visions of the Future"
              subtitle="A Note From Our Founders Eric and Wendy Schmidt"
              image_url="words-founders-img.webp"
              bg_url="words-founders-bg.png"
            >
              <WordsFromOurFounders />
            </SubPage>
          }
        />

        <Route
          path="/10-year-soi-expeditions-map"
          exact
          element={
            <SubPage
              group="SOI Remastered"
              title="10-Year SOI Expeditions Map"
              subtitle="One Ocean, Seven Continents"
              image_url="ten-years-img.webp"
              bg_url="ten-years-bg.png"
            >
              <TenYearsMap />
            </SubPage>
          }
        />
        <Route
          path="/new-proposal-process"
          exact
          element={
            <SubPage
              group="SOI Remastered"
              title="New Proposal Process"
              subtitle=""
              image_url="proposal-img.webp"
              bg_url="proposal-bg.png"
            >
              <Proposal />
            </SubPage>
          }
        />
        <Route
          path="/celebrating-a-seaworthy"
          exact
          element={
            <SubPage
              group="SOI Remastered"
              title="Celebrating a Seaworthy R/V Falkor (too)"
              subtitle=""
              image_url="celebrating-img.webp"
              bg_url="celebrating-bg.png"
            >
              <Celebrating />
            </SubPage>
          }
        />
        <Route
          path="/rv-falkor-too"
          exact
          element={
            <SubPage
              group="SOI Remastered"
              title="R/V Falkor (too)"
              subtitle=""
              image_url="rvfalkor-img.webp"
              bg_url="rvfalkor-bg.png"
            >
              <RVFalkor />
            </SubPage>
          }
        />

        <Route
          path="/the-future-faces-of-ocean-science"
          exact
          element={
            <SubPage
              group=""
              title={"The Future Faces\nof Ocean Science"}
              subtitle=""
              image_url="future-faces-img.webp"
              bg_url="future-faces-bg.png"
            >
              <FutureFaces
                faces1Ref={faces1Ref}
                faces2Ref={faces2Ref}
                faces3Ref={faces3Ref}
                faces4Ref={faces4Ref}
                faces5Ref={faces5Ref}
                faces6Ref={faces6Ref}
                faces7Ref={faces7Ref}
                faces8Ref={faces8Ref}
                faces9Ref={faces9Ref}
              />
            </SubPage>
          }
        />

        <Route
          path="/a-limitless-ocean-of-data"
          exact
          element={
            <SubPage
              group=""
              title="A Limitless Ocean of Data"
              subtitle="SOI’s Work with Data"
              image_url="limitless-img.webp"
              bg_url="limitless-bg.png"
            >
              <Limitless />
            </SubPage>
          }
        />

        {window.innerWidth > 1200 ? (
          <Route
            path="/"
            exact
            element={
              <Home
                byTheNumbersRef={byTheNumbersRef}
                anthologyRef={anthologyRef}
                deepSeaRef={deepSeaRef}
                disseminatingRef={disseminatingRef}
                soiRef={soiRef}
                centerRef={centerRef}
                futureRef={futureRef}
                reflectionsRef={reflectionsRef}
                visionsRef={visionsRef}
              />
            }
          />
        ) : (
          <Route
            path="/"
            exact
            element={
              <HomeMobile
                byTheNumbersRef={byTheNumbersRef}
                anthologyRef={anthologyRef}
                deepSeaRef={deepSeaRef}
                disseminatingRef={disseminatingRef}
                soiRef={soiRef}
                centerRef={centerRef}
                futureRef={futureRef}
                reflectionsRef={reflectionsRef}
                visionsRef={visionsRef}
              />
            }
          />
        )}
      </Routes>
    </>
  );
}

export default App;
