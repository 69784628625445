import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function human() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL + "/images/subpages/internas/human-rec1.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/biodiverse-borderlands/"
            target="_blank"
          >
            Biodiverse Borderlands
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Lisa Levin, University of California San Diego,
          Scripps Institution of Oceanography
        </h4>
        <p>
          In 2021, R/V <span className="falkor-format">Falkor</span> sailed to
          the Southern California Borderlands to study ecosystems affiliated
          with mineral-rich environments. The science team’s goals were to
          establish mineral and biological baselines, examine conditions that
          influence the biodiversity of the region, and inspect the therapeutic
          potential of deep-sea microbes affiliated with these mineral-rich
          areas. The team also expanded their initial science goals and spent
          two days exploring a nearby Dichlorodiphenyltrichloroethane (DDT)
          waste barrel disposal site, where{" "}
          <a
            className="link-int-page"
            href="https://www.latimes.com/projects/la-coast-ddt-dumping-ground/#nt=00000180-d852-d1f7-a1e2-df5e167b0001-showMedia-liLT0promoMedium"
            target="_blank"
          >
            dumping took place between 1947-1982
          </a>
          . ROV <span className="falkor-format">SuBastian</span> collected
          sediment cores around the barrels to study the DDT’s effect on
          microbes and animals in the environment. Following the expedition,
          researchers from the Scripps Institution of Oceanography secured
          multi-million dollar{" "}
          <a
            className="link-int-page"
            href="https://scripps.ucsd.edu/news/uc-san-diegos-scripps-institution-oceanography-awarded-56-million-southern-california-ddt"
            target="_blank"
          >
            funding from NOAA
          </a>{" "}
          to continue to investigate how the DDT dumpsite affects southern
          California’s marine ecosystems. They will analyze samples from the
          barrel’s surfaces, surrounding sediment and push cores, and video
          footage to assess the distribution and concentration of DDT in the
          environment.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL + "/images/subpages/internas/human-rec2.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/coralandcanyonadventure/"
            target="_blank"
          >
            The Great Australian Deep-Sea Coral and Canyon Adventure
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Julie Trotter, University of Western Australia
        </h4>
        <p>
          R/V <span className="falkor-format">Falkor</span>’s first adventure in
          Australia’s waters took place in the canyon system offshore
          southwestern Australia. This was the first ROV-based deep sea
          exploration of the Bremer canyon system and Perth Canyon.
          Comprehensive physical and chemical measurements of the canyon waters
          were coupled with ROV surveys to give the science team a baseline
          understanding of the environmental conditions, including the
          anthropogenic impacts. The team closely studied the anthropogenic
          impacts of the canyons,{" "}
          <a
            className="link-int-page"
            href="https://www.sciencedirect.com/science/article/pii/S0048969722063422?via%3Dihub"
            target="_blank"
          >
            quantifying the presence of macro-litter seen during the ROV surveys
          </a>
          . Their analysis showed that these Australian canyons were much less
          impacted compared to other canyons worldwide and that the megabenthos,
          including deep water corals, were not yet affected by macro-litter.
          This is good news for the stewardship of the deep-sea environment and
          provides baseline information for monitoring a relatively pristine
          area.
        </p>
      </div>
    </>
  );
}

export default human;
