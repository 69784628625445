import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Proposal() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/proposal-rec1.webp"
          }
          alt="img"
        />
        <p>
          One of the big changes to Schmidt Ocean Institute’s (SOI) proposal
          process is that we will accept{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/expression-of-interest-2023/"
            target="_blank"
          >
            Expressions of Interest
          </a>{" "}
          (EOI) submissions for R/V{" "}
          <span className="falkor-format">Falkor (too)</span> science and
          technology projects on a rolling basis. EOIs may be submitted for
          collaborative, multidisciplinary projects to be conducted aboard R/V{" "}
          <span className="falkor-format">Falkor (too)</span> at any time, for
          any location and year identified on the 10-year{" "}
          <a
            className="link-int-page"
            href="https://strategicframework.schmidtocean.org/concept/"
            target="_blank"
          >
            SOI Expeditions Map
          </a>
          . Investigators may request R/V{" "}
          <span className="falkor-format">Falkor (too)</span>’s full allotment
          of berths available for science in an EOI or may be partnered with
          additional projects at the full proposal stage to maximize the use of
          the vessel. Another substantial change to the proposal process is that
          as an alternative to written EOIs, video proposals may be submitted.
          This change supports SOI’s commitment to being more inclusive and
          allows for greater international participation among non-native
          English speakers who may prefer to propose their scientific project
          visually or orally instead of in writing.
        </p>
      </div>
    </>
  );
}

export default Proposal;
