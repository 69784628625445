import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Awards() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/awards-rec1.webp"
          }
          alt="img"
        />
        <p>
          Australia’s{" "}
          <a
            className="link-int-page"
            href="https://www.deepreef.org/2022/11/10/soi-award/"
            target="_blank"
          >
            SSSI Hydrography Commission’s Hydrographic Excellence Award
          </a>{" "}
          was bestowed to SOI for R/V{" "}
          <span className="falkor-format">Falkor’</span>s operations and
          contributions to mapping the seafloor in Australia, including the
          discovery of a new coral reef during an expedition led by Dr. Robin
          Beaman from James Cook University. He received the award at the 2022
          NSW & ACT Asia-Pacific Spatial Excellence Awards dinner, on behalf of SOI staff, R/V <span className="falkor-format">Falkor</span> crew,
          and science team.
        </p>
        <p>
          In June, SOI accepted the Conservation Innovation Award on behalf of
          SOI co-founders Eric and Wendy Schmidt for their important Ocean work
          through The Schmidt Family Foundation, Schmidt Marine Technology
          Partners, SOI, and 11th Hour Racing. The award was bestowed upon them
          by the National Marine Sanctuary Foundation and was awarded during
          Capitol Hill Ocean Week in Washington, D.C.
        </p>
        <p>
          SOI was also honored to receive four MarCom Awards in 2022, an
          international competition for marketing and communications
          professionals. The awards included two platinum for the Virtual
          Aquarium website (in partnership with the World Ocean Observatory) and
          the{" "}
          <a
            className="link-int-page"
            href="https://youtu.be/-dXCLYypbpU"
            target="_blank"
          >
            Depths of Ningaloo
          </a>{" "}
          short documentary film, a gold for the{" "}
          <a
            className="link-int-page"
            href="https://2021annualreport.schmidtocean.org/?_ga=2.161624124.1675835530.1680453868-542219080.1620776639&_gl=1%2Aq51411%2A_ga%2ANTQyMjE5MDgwLjE2MjA3NzY2Mzk.%2A_ga_ERTHMRGPNW%2AMTY4MDY2NzM2Ny44NTMuMS4xNjgwNjY3NDAyLjAuMC4w"
            target="_blank"
          >
            2021 SOI Impact Report
          </a>
          , and an honorable mention for the{" "}
          <a
            className="link-int-page"
            href="https://www.youtube.com/watch?v=YZmhis5hZxg&list=PLJGVqQI3okzbArZ1orJXmhdB8zrmIWkuZ"
            target="_blank"
          >
            Making of a Research Vessel
          </a>{" "}
          YouTube series that showcases the refit of research vessel{" "}
          <span className="falkor-format">Falkor (too)</span>.
        </p>
      </div>
    </>
  );
}

export default Awards;
