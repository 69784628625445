import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Museum() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL + "/images/subpages/internas/museum-rec1.webp"
          }
          alt="img"
        />
        <p>
          Since 2016, SOI has been sharing expedition content with museums and
          aquariums across the globe. In 2022, expedition content was included
          in five exhibits. The traveling exhibit{" "}
          <span className="falkor-format">AI: More than Human</span>, featured
          footage from the 2018{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/new-approaches-autonomous-exploration-crsb/"
            target="_blank"
          >
            New Approaches to Autonomous Exploration
          </a>{" "}
          expedition and was hosted at the{" "}
          <a
            className="link-int-page"
            href="https://twitter.com/sonycslparis/status/1510932910014185477?lang=bn"
            target="_blank"
          >
            Guandong Science Center
          </a>{" "}
          (China) from January to June and the{" "}
          <a
            className="link-int-page"
            href="https://www.afundacion.org/es/agenda/evento/exposicion-more-than-human-coruna"
            target="_blank"
          >
            Afundación
          </a>{" "}
          (Spain) from September to February (2023). Both locations received a
          combined total of 249,448 visitors. Other exhibits that featured SOI
          content included Ocean Wonders—Australian National Maritime Museum,
          Into the Deep: Exploring our Undiscovered Oceans—Monterey Bay Aquarium
          (United States), and the Deep Sea Showcase—Western Australia Museum.
        </p>
      </div>
    </>
  );
}

export default Museum;
