import '../stylesheets/App.css';
import '../stylesheets/Loader.css';
import React from 'react';
import { useNavigate } from "react-router-dom";


function Loader(props) {

    return (
        <>
            <div className="loader-full-container">
                    <div className="loader-container">
                        <img className="intro-loader" src={process.env.PUBLIC_URL+"/images/home/spinner.png"} alt="loading"/>
                    </div>
            </div>

        </>
    );
}

export default Loader;