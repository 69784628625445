import '../../../stylesheets/SubPage.css';
import React, {useRef, useEffect} from 'react';
import CarouselComponent from '../../Carousel';

function RVFalkor() {

  const imageList = [
    '/images/subpages/internas/falkor-car1.webp',
    '/images/subpages/internas/falkor-car2.webp',
    '/images/subpages/internas/falkor-car3.webp',
    '/images/subpages/internas/falkor-car4.webp',
    '/images/subpages/internas/falkor-car5.webp',
    '/images/subpages/internas/falkor-car6.webp',
    '/images/subpages/internas/falkor-car7.webp',
    '/images/subpages/internas/falkor-car8.webp',
    '/images/subpages/internas/falkor-car9.webp',
    '/images/subpages/internas/falkor-carA.webp'
    
  ];

   return (
    <>
    <div className="subpage-recuadro general-text">
            <p>For a few months in early 2022, R/V <span className="falkor-format">Falkor</span> and its successor, R/V <span className="falkor-format">Falkor (too)</span>, berthed side-by-side at the Freire Shipyard in Vigo, Spain. The resulting photographs capture an indelible image: the new ship looming over its predecessor, filling the frame, the ambitions of an organization writ large. It’s tempting to label this sparkling behemoth a “replacement” or an “upgrade.” But R/V <span className="falkor-format">Falkor (too)</span> isn’t just Schmidt Ocean Institute’s vessel of the future—it reimagines the possibilities for all oceanographic research vessels.</p>
            <p>Few research vessels afloat outstrip R/V <span className="falkor-format">Falkor (too)</span> for size. It plies the Ocean with a 110-meter length and a 20-meter beam. The aft deck sprawls across 960 square meters—larger than two NBA basketball courts combined. It’s a blank canvas that’s presided over by a massive 150-ton active heave-compensating crane able to rearrange equipment, containers, and control vans while in port or underway. These numbers represent a capacious and capable platform for launching research technologies that ride the edge of imagination.</p>
            <p>In addition to the endlessly adaptable aft deck, the ship features two moon pools and multiple launch-and-recovery systems for vehicles—including one for ROV <span className="falkor-format">SuBastian</span>. After six years of taking a global audience deep into the crushing unknown, the newly overhauled robot will be lifted directly from the sea into an interior hangar filled with eager scientists—no longer retrieving samples under the blistering equatorial sun or lashing Ocean rains.</p>
            <div className="carousel-container">
            <CarouselComponent imageList={imageList} />
            </div>
            <p>Now a short journey awaits those sample-bearing scientists after leaving the comfort of the hangar. During the refit process, an entire floor of cabins was removed and rebuilt into the true heart of <span className="falkor-format">Falkor (too)</span>—eight distinct and dedicated laboratory spaces. The first stop might be the 105-square-meter Main Lab, a versatile and configurable space that can adapt to the multidisciplinary needs of any science party. From there, maybe the Cold Lab. Or the Dirty Wet Lab. Or the Robotics Lab. Science needs space, and <span className="falkor-format">Falkor (too)</span> has it in unprecedented abundance.</p>
            <p>As a state-of-the-art mobile research facility, the ship bristles with numerous oceanographic, atmospheric, and navigational sensors. The high-performance computing system on board this research vessel allows for unparalleled real-time data-processing capabilities for researchers. Built into the hull is a 19-meter gondola packed with the latest sonar systems. Technological advancements are ceaseless, but R/V <span className="falkor-format">Falkor (too)</span> is designed to be as future-proof as possible—with extra space, including on the specially built fore- and main-masts, reserved for sensors not yet imagined.</p>
            <p>Despite the marvels of electronics and robotics, at its core, Ocean science is people-powered. It’s built on the efforts of an interdisciplinary global community, and R/V <span className="falkor-format">Falkor (too)</span> dramatically increases our ability to bring that community to sea. With berths for 96 people, it more than doubles the original ship’s 44-person limit. This means more scientists, artists, and berths of opportunity for students and stakeholders. When R/V <span className="falkor-format">Falkor (too)</span> sails, it transports an entire village to unveil the mysteries of our vast and deep Ocean.</p>
            <p>Powered by the world’s largest Voith-Schneider propulsion system, R/V <span className="falkor-format">Falkor (too)</span> tirelessly treads the Ocean. With an ice rating up to 15 centimeters and an endurance that can outlast most detailed expedition plans, its range is truly global—ensuring its place at the forefront of oceanographic research across our one Ocean and every continent on Earth.</p>
            <p>And while it’s true that R/V <span className="falkor-format">Falkor</span> literally floated in the shadow of the new ship for those few special months in Vigo, Spain, the amazing discoveries and unprecedented research accomplished on the original <span className="falkor-format">Falkor</span> will never be forgotten. As the transition of cross-decking equipment took place, one could almost imagine a conversation between the two Luck Dragons—a sage elder passing along wisdom to an enthusiastic protégé as they both prepared for the next step in their never-ending journeys.</p>
    </div>
        
    </>
  );
}

export default RVFalkor;