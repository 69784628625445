import '../stylesheets/Home.css';
import Center from './homeSections/Center';
import Future1 from './homeSections/Future1';
import Future2 from './homeSections/Future2';
import Past1 from './homeSections/Past1';
import Past2 from './homeSections/Past2';
import Past3 from './homeSections/Past3';
import Past4 from './homeSections/Past4';
import Loader from './Loader';
import React, {useRef, useEffect, useState} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { goToNextSection, goToPrevSection } from '../helpers/helperNavigation';
gsap.registerPlugin(ScrollTrigger);



function Home(props) {
    let [loading, setLoading] = useState(true);
    const imgRef = useRef();
    window.addEventListener("scroll", ()=>{
      /*
        console.log("y: "+window.scrollY)
        console.log("x: "+window.scrollX)
        console.log("Ancho del contenedor:"+document.querySelector(".container").offsetWidth)
        console.log("Ancho del contenedor:"+document.querySelector(".container").clientWidth)*/
    })
   

    


   useEffect(()=>{
    setTimeout(()=>setLoading(false), 5000);
  //ubico la pantalla en la mitad del timeline
  setTimeout(()=>{
    let puntoMedio = document.getElementById("center-panel").offsetTop;
    //console.log("punto medio mobile: "+puntoMedio);
    window.scroll(0,puntoMedio);
    /*modifico las imagenes del mouse para mobile*/
    /*
    if(window.innerWidth<=1200){
      let mouseLeft = document.querySelector(".home-mouse-item-container.left .home-mouse");
      let mouseRight = document.querySelector(".home-mouse-item-container.right .home-mouse");
      mouseLeft.setAttribute("src", process.env.PUBLIC_URL+"/images/home/mouse_up.png");
      mouseRight.setAttribute("src", process.env.PUBLIC_URL+"/images/home/mouse_down.png");
    }*/
    
    /*animacion inicial*/
    
    let mousesContainer = document.querySelectorAll(".home-mouse-item-container");
    gsap.timeline().to(mousesContainer[0], { 
      top:'+=20',
      duration:1, 
      delay:1
    }).to(mousesContainer[0], { 
      top:'-=20',
      duration:1,
      delay:0
    }).to(mousesContainer[1], { 
      bottom:'+=20',
      duration:1, 
      delay:-2
    }).to(mousesContainer[1], { 
      bottom:'-=20',
      duration:1,
      delay:-1
    })
    let logo = document.querySelector(".home-logo");
    gsap.timeline().to(logo, {
      rotate:30,
      duration:2,
      delay:1
    }).to(logo, {
      rotate:20,
      duration:1
    })

    let sections = gsap.utils.toArray(".panel");
    /*center section*/
    /*
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sections[4],
        start: 'left 90%',
        end: 'right 10%',
        scrub:1,
      }
    });
    tl.to(logo, {
      rotate:30
    }).to(logo, {
      rotate:20
    }).to(logo, {
      rotate:30
    }).to(logo, {
      rotate:20
    })
    
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[4],
        start: 'left 50%',
        end: 'right 50%',
        scrub:1, 
        duration:2,
      }
    });
    for(let i=0;i<4;i++){
      tl2.to(mousesContainer[0], { 
        top:'+=20',
        duration:1, 
        delay:1
      }).to(mousesContainer[0], { 
        top:'-=20',
        duration:1,
        delay:0
      }).to(mousesContainer[1], { 
        bottom:'+=20',
        duration:1, 
        delay:-2
      }).to(mousesContainer[1], { 
        bottom:'-=20',
        duration:1,
        delay:-1
      })
    }*/
    /*future1 section*/
    /*
    let cardFuture1section1Text = document.querySelector(".future1-section1 .static-card-title-container");
  let cardFuture1section1Image = document.querySelector('.future1-section1 .static-card-img');


  const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[5],
        start: 'left 80%',
        end: 'right 110%',
        scrub:1,
        duration:5
      }
    }).to(cardFuture1section1Image, {
      delay:-3,
      opacity:1,
      duration:2
    }).to(cardFuture1section1Text, {
      delay:-3,
      opacity:1,
      duration:2
    })*/

    /*future1 section2*/
    /*
    let future1Section2titles= [document.querySelector(".future1-section2 .future1-title"), document.querySelector(".future1-section2 .future1-subtitle")];
    let future1Section2Description = document.querySelector(".future1-panel .future1-description")
    let future1Section2Cards = document.querySelectorAll(".cards-future1-container .card-container")
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: '.future1-section2',
        start: 'left 60%',
        end: 'right 80%',
        scrub:1,
        duration:8, 
      }
    }).to(future1Section2titles, {
      opacity:1,
      duration:2,
      stagger: {
        amount:0
      }
    }).to(future1Section2Description, {
      delay:0,
      opacity:1,
      duration:1
    }).to(future1Section2Cards, {
      opacity:1,
      duration:6,
      stagger: {
      each: 1, // tiempo de retraso entre cada animación, en segundos
      from: "start" // comenzar la animación desde el inicio del arreglo
  }
    })*/

    /*future2*/
    /*
    let cardFuture2Description = document.querySelectorAll(".future2-panel .future2-card-container .static-card-title-container");
    let cardFuture2Image = document.querySelectorAll('.future2-card-container .static-card-img');

    const tl5 = gsap.timeline({
        scrollTrigger: {
          trigger: sections[6],
          start: 'left 70%',
          end: 'right 100%',
          scrub:1,
          duration:5
        }
      }).to(cardFuture2Description[0], {
        delay:-1,
        opacity:1,
        duration:2
      }).to(cardFuture2Image[0], {
        delay:-2,
        opacity:1,
        duration:2
      }).to(cardFuture2Description[1], {
        delay:3,
        opacity:1,
        duration:2
      }).to(cardFuture2Image[1], {
        delay:-2,
        opacity:1,
        duration:2
      })

  */
    /*past1*/
    /*
    let past1CardDescription = document.querySelector(".past1-card-container .static-card-title-container");
    let past1CardImage = document.querySelector(".past1-card-container .static-card-img");
    let byTheNumbersItems = document.querySelectorAll(".past1-info-container .numbers-item");
    const tl7 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[3],
        start: 'left 20%',
        end: 'right 40%',
        scrub:1,
        duration:5,
      }
    }).to(byTheNumbersItems, {
      opacity:0,
        duration:6,
        stagger: {
            each: 1, // tiempo de retraso entre cada animación, en segundos
            from: "start" // comenzar la animación desde el inicio del arreglo
        }
    }).to(past1CardImage, {
      opacity:0,
      duration:1,
      delay:-1
    }).to(past1CardDescription, {
      opacity:0,
      duration:1,
      delay:-1
    })*/

    /*past2*/
    /*
    let past2Titles = [document.querySelector(".past2-panel .title-container h1"), document.querySelector(".past2-panel .title-container h2")];
    let past2Items = document.querySelectorAll('.past2-flex-container .past2-item');

    const tl8 = gsap.timeline({
        scrollTrigger: {
          trigger: sections[2],
          start: 'left 0%',
          end: 'right 10%',
          //markers:true,
          scrub:1,
        }
      }).to(past2Titles[0], {
        opacity:0,
        duration:1,
      }).to(past2Titles[1], {
        opacity:0,
        duration:1,
      }).to(past2Items, {
        opacity:0,
        duration:6,
        stagger: {
            each: 1, // tiempo de retraso entre cada animación, en segundos
            from: "start" // comenzar la animación desde el inicio del arreglo
        }
      })*/

       /*past3*/
       /*
    let past3Titles = [document.querySelector(".past3-panel .title-container h1"), document.querySelector(".past3-panel .title-container h2")];
    let past3Description = document.querySelectorAll('.past3-panel .title-container .general-text');
    let past3Items = document.querySelectorAll('.past3-panel .cards-container .vertical-card');

    const tl9 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[1],
        start: 'left 0%',
        end: 'right 70%',
        //markers:true,
        scrub:1,
      }
    }).to(past3Titles[0], {
      opacity:0,
      duration:1,
    }).to(past3Titles[1], {
      opacity:0,
      duration:1,
    }).to(past3Description, {
      opacity:0,
      duration:1,
    }).to(past3Items, {
      opacity:0,
      duration:6,
      stagger: {
          each: 1, // tiempo de retraso entre cada animación, en segundos
          from: "start" // comenzar la animación desde el inicio del arreglo
      }
    })*/

    /*past4*/
    /*
    let past4Titles = [document.querySelector(".past4-panel .title-container h1"), document.querySelector(".past4-panel .title-container h2")];
    let past4Description = document.querySelectorAll('.past4-panel .title-container .general-text');
    let past4Items = document.querySelectorAll('.past4-panel .cards-container .card-container');

    const tl10 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[0],
        start: 'left 0%',
        end: 'right 70%',
        scrub:1,
      }
    }).to(past4Titles[0], {
      opacity:0,
      duration:1,
    }).to(past4Titles[1], {
      opacity:0,
      duration:1,
    }).to(past4Description, {
      opacity:0,
      duration:1,
    }).to(past4Items, {
      opacity:0,
      duration:6,
      stagger: {
          each: 1, // tiempo de retraso entre cada animación, en segundos
          from: "start" // comenzar la animación desde el inicio del arreglo
      }
    })*/
    
    
    

  }, 2000);
  
  
    

    }, []);

   return (
    <div>
      {loading?<Loader/>:''}
      
        <div className="container mobile-container">
            <Past4 disseminatingRef={props.disseminatingRef}/>
            <Past3 deepSeaRef={props.deepSeaRef} />
            <Past2  anthologyRef={props.anthologyRef}/>
            <Past1  reflectionsRef={props.reflectionsRef} byTheNumbersRef={props.byTheNumbersRef}/>
            <Center centerRef = {props.centerRef} />
            <Future1 visionsRef={props.visionsRef} soiRef={props.soiRef}/>
            <Future2 futureRef = {props.futureRef}/>
        </div>
          <div className="falkor-img" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/home/falkor.png"+")"}}/>
          <div className="falkor-too-img" style={{backgroundImage: "url("+process.env.PUBLIC_URL+"/images/home/falkor_too.png"+")"}}/>
          <img style={{display:'none'}} onClick={()=>goToPrevSection(props)} className="home-nav-btn prev-nav-btn" src={process.env.PUBLIC_URL+"/images/arrows/arrow-up-nav.png"} alt="up-arrow"/>
          <img style={{display:'none'}} onClick={()=>goToNextSection(props)} className="home-nav-btn next-nav-btn" src={process.env.PUBLIC_URL+"/images/arrows/arrow-down-nav.png"} alt="down-arrow"/>
    </div>
  );
}

export default Home;