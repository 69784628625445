import '../../stylesheets/App.css';
import React, {useRef, useEffect} from 'react';
function Center(props) {

    
   return (
    <>
        <section ref={props.centerRef} className="panel home-panel center-panel flex" id="center-panel">
            <div className="home-img" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/home/bg1.png)"}}></div>
            <div className="home-principal-content" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/home/bg-center.png)"}}>
                <div className="home-title-mobile-flex">
                    <div className="home-icon-container flex">
                        <img className="home-logo" src={process.env.PUBLIC_URL+"/images/home/logo_parte1.png"} alt="logo-soi"/>
                        <img className="home-logo-float" src={process.env.PUBLIC_URL+"/images/home/logo_parte2.png"} alt="logo-soi"/>
                    </div>
                    <h1 className="title home-title">Our{window.innerWidth>1200?<br/>:' '}Never-{window.innerWidth<=1200?<br/>:''}Ending Journey</h1>
                </div>
                <p className="general-text home-principal-text">Since 2009, Schmidt Ocean Institute has been committed to excellence in oceanographic research. Over one thousand international scientists have participated in eighty-one expeditions on R/V <span className="falkor-format">Falkor</span>, revealing stunning details on what lies in our Ocean. Join us in looking back at our body of work from the past decade and ahead to our future, as we continue the never-ending journey of Ocean exploration.</p>
                <div className="home-mouse-container flex">
                    <div className="home-mouse-item-container left">
                        <img className="home-arrow" src={process.env.PUBLIC_URL+"/images/arrows/arrow-left-home.png"} alt="arrow"/>
                        <div className="flex-start">
                            <p className="general-text">Scroll towards the</p>
                            <h2>Past</h2>
                        </div> 
                    </div>
                    <div className="home-mouse-item-container right">
                        <div className="flex-end">
                            <p className="general-text">Scroll towards the</p>
                            <h2>Future</h2>
                        </div>
                        <img className="home-arrow" src={process.env.PUBLIC_URL+"/images/arrows/arrow-right-home.png"} alt="arrow"/>
                    </div>
                </div>
            </div>
            <div className="home-img" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/home/bg2.webp)"}}></div>
            <div className="falkor-img-static hiddenFalkor" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/home/falkor.png"+")"}}/>
            <div className="falkor-too-img-static hiddenFalkor" style={{backgroundImage: "url("+process.env.PUBLIC_URL+"/images/home/falkor_too.png"+")"}}/>
            
        </section>

    </>
  );
}

export default Center;