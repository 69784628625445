import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function limitless() {
  return (
    <>
      <div className="subpage-recuadro no-min-height general-text">
        <p>
          Open data sharing is a core principle of Schmidt Ocean Institute
          (SOI). Through openly sharing our data, we improve the accuracy of
          data-driven tools, enhance the value of outcomes from data
          applications, and increase the speed at which the scientific community
          obtains new Ocean knowledge. SOI has been collecting data and making
          it publicly available via data centers since 2015. The value of our
          data grows by making it easier to find, more accessible,
          interoperable, and reusable.
        </p>
        <p>
          SOI is committed to collecting and disseminating high-quality
          oceanographic and atmospheric data. During 2022, SOI developed its
          first Data Strategy that will result in a more robust data pipeline
          for SOI-collected data. Achieving the strategy's goals will require
          the creation of sophisticated tools and platforms that improve data
          access, automation of quality assurance and control processes, and
          software application development to derive additional value from our
          data.
        </p>
        <p>
          The six themes of SOI's Data Strategy are: data collection, data use, data accessabililty, data solutions, data products, and novel data methods.
          <ul className="list-disc limitless-list">
            <li>
              <span className="falkor-format">Data Collection</span>
            </li>
            <li>
              <span className="falkor-format">Data Use</span>
            </li>
            <li>
              <span className="falkor-format">Data Accessibility</span>
            </li>
            <li>
              <span className="falkor-format">Data Solutions</span>
            </li>
            <li>
              <span className="falkor-format">Data Products</span>
            </li>
            <li>
              <span className="falkor-format">Novel Data Methods</span>
            </li>
          </ul>
        </p>
        <p>
          In addition to improving the flow of data from the new science and
          communication systems on board R/V <span className="falkor-format">Falkor (too)</span>, several new data
          platform developments and collaborations took place in 2022.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/limitless-rec2.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">Dive Vault </h3>

        <p>
          In order to make ROV <span className="falkor-format">SuBastian</span>{" "}
          dive videos easier to find and more accessible,{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.github.io/dive-vault/"
            target="_blank"
          >
            Dive Vault
          </a>{" "}
          was developed in-house as an open-source tool for exploring SOI’s
          YouTube livestream video library. Dive Vault displays the locations of
          all the dives performed by ROV{" "}
          <span className="falkor-format">SuBastian</span>. The application
          allows users to explore the archive of videos through a web-based map
          interface, making it simple to find and review past dives based on
          geographical areas of interest. As dives are performed on R/V{" "}
          <span className="falkor-format">Falkor (too)</span>, they will also be
          added to this platform.
        </p>
      </div>

      <div className="subpage-recuadro general-text flex-rec">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/limitless-rec3.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">
            FathomNet and Ocean Vision AI
          </h3>

          <p>
            <a
              className="link-int-page"
              href="https://fathomnet.org/fathomnet/#/"
              target="_blank"
            >
              FathomNet
            </a>
            , developed by researchers at the Monterey Bay Aquarium Research
            Institute (MBARI), is an open-source image database that can be used
            to train, test, and validate AI algorithms to help understand our
            Ocean and its inhabitants. SOI is currently working on methods to
            contribute imagery collected by ROV{" "}
            <span className="falkor-format">SuBastian</span> to the FathomNet
            database. Also developed by MBARI, in partnership with the Ocean
            Discovery League and others, is{" "}
            <a
              className="link-int-page"
              href="https://www.mbari.org/news/ocean-vision-ai-uses-the-power-of-artificial-intelligence-to-process-ocean-imagery/"
              target="_blank"
            >
              Ocean Vision AI
            </a>
            , a machine learning platform that identifies and processes underwater
            imagery. Using FathomNet and Ocean Vision AI together, images and
            metadata are quickly annotated and made accessible, increasing the
            useability of visual data.
          </p>
        </div>
      </div>

      <div className="subpage-recuadro general-text flex-rec">
        <img
          className="float with-border"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/limitless-rec4.webp"
          }
          alt="img"
        />
        <div  className="text-container">
          <h3 className="subpage-rec-title mt-0">University of Chicago</h3>
          <p>
            SOI continued its collaboration with the University of Chicago Data
            Science Institute, which trains students to become the next generation
            of data scientists. Using data provided by SOI from R/V{" "}
            <span className="falkor-format">Falkor</span> and ROV{" "}
            <span className="falkor-format">SuBastian</span>, students worked on
            multiple projects, including developing methods for merging ROV sensor
            and imagery data into analysis-ready data, which various user groups
            can utilize for research and machine learning. Students also created
            machine learning models, incorporating techniques, code, and annotated
            images from FathomNet, to more rapidly identify objects and scenes of
            interest in ROV videos.
          </p>
        </div>
      </div>

      <div className="subpage-recuadro general-text flex-rec">
        <img
          className="float with-border"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/limitless-rec5.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Schmidt Futures</h3>
          <p>
            SOI collaborated with the <a
              className="link-int-page"
              href="https://www.schmidtfutures.com/our-work/technologists-for-global-transformation/"
              target="_blank">Schmidt Futures Technologists for Global Transformation (TGT</a><span className="blue-text">)</span> program, formerly known as the Associate Product Manager program. The
            TGT program provides career-broadening opportunities to early career
            technologists to solve systemic global challenges for public benefit.
            SOI’s Data Solutions Architect worked closely with a TGT participant
            to review, advise, and develop a strategy for creating a portal that
            would allow public access and efficient distribution of SOI’s
            scientific imagery.
          </p>
        </div>
      </div>

      <div className="subpage-recuadro general-text flex-rec">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/limitless-rec6.webp"
          }
          alt="img"
        />
        <div className="text-container">
            <h3 className="subpage-rec-title mt-0">
              Open Source Collaboration via GitHub
            </h3>

            <p>
              As part of our open-source initiatives, SOI has established a{" "}
              <a
                className="link-int-page"
                href="https://github.com/schmidtocean"
                target="_blank"
              >
                GitHub
              </a>{" "}
              organization that will assist in the dissemination and collaboration
              of open-source code, tools, and tutorials. GitHub and similar
              collaborative version control platforms are ubiquitous in the data and
              earth science community. SOI, with other data partners, compiled a
              list of open ocean data repositories,{" "}
              <a
                className="link-int-page"
                href="https://github.com/schmidtocean/awesome-ocean-data-resources"
                target="_blank"
              >
                awesome-data-repos
              </a>
              , which is posted on the SOI{" "}
              <a
                className="link-int-page"
                href="https://github.com/schmidtocean/awesome-ocean-data-resources"
                target="_blank"
              >
                GitHub
              </a>
              . The initial list was based on supplemental materials from{" "}
              <span className="falkor-format">
                Disrupting Data Sharing for a Healthier Ocean
              </span>
              , with additional compilation work from representatives of multiple
              organizations, including HUB Ocean. The Ocean community can comment,
              add, and edit the list, and multiple users have already done so.
            </p>
        </div>
      </div>
    </>
  );
}

export default limitless;
