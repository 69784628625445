import "../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";
import RelatedItem from "./RelatedItem";

function RelatedGroup(props) {
  const lista = [
    {
      title: "Reflections from the Past",
      image: "directors-note-img.webp",
      route: "/directors-note",
      group: "",
    },
    {
      title: "Mapping the Seafloor",
      image: "seafloor-img.webp",
      route: "/mapping-the-seafloor",
      group: "A Scientific Anthology",
    },
    {
      title: "Frontiers of Biodiversity",
      image: "frontiers-img.webp",
      route: "/frontiers-of-biodiversity",
      group: "A Scientific Anthology",
    },
    {
      title: "Stability of Ocean Health",
      image: "stability-img.webp",
      route: "/stability-of-ocean-health",
      group: "A Scientific Anthology",
    },
    {
      title: "Power of Nature",
      image: "power-img.webp",
      route: "/power-of-nature",
      group: "A Scientific Anthology",
    },
    {
      title: "Exploring the Deep",
      image: "deep-img.webp",
      route: "/exploring-the-deep",
      group: "A Scientific Anthology",
    },
    {
      title: "Technologies for the Future",
      image: "technologies-img.webp",
      route: "/technologies-for-the-future",
      group: "A Scientific Anthology",
    },
    {
      title: "The Human Effect",
      image: "human-img.webp",
      route: "/the-human-effect",
      group: "A Scientific Anthology",
    },
    {
      title: "Grants",
      image: "grants-img.webp",
      route: "/grants",
      group: "The Deep Sea Continuum",
    },
    {
      title: "Strategic Partnerships",
      image: "strategic-img.webp",
      route: "/strategic-partnerships",
      group: "The Deep Sea Continuum",
    },
    {
      title: "Leadership",
      image: "leadership-img.webp",
      route: "/leadership",
      group: "The Deep Sea Continuum",
    },
    {
      title: "Artist-at-Sea",
      image: "artists-img.webp",
      route: "/artists-at-sea",
      group: "Disseminating the Depths",
    },
    {
      title: "Awards",
      image: "awards-img.webp",
      route: "/awards",
      group: "Disseminating the Depths",
    },
    {
      title: "Museum Exhibits",
      image: "museum-img.webp",
      route: "/museum-exhibits",
      group: "Disseminating the Depths",
    },
    {
      title: "Press and Footage",
      image: "press-img.webp",
      route: "/press-and-footage",
      group: "Disseminating the Depths",
    },
    {
      title: "Visions of the Future",
      image: "words-founders-img.webp",
      route: "/words-from-our-founders",
      group: "",
    },
    {
      title: "10-year SOI Expeditions Map",
      image: "ten-years-img.webp",
      route: "/10-year-soi-expeditions-map",
      group: "SOI Remastered",
    },
    {
      title: "New Proposal Process",
      image: "proposal-img.webp",
      route: "/new-proposal-process",
      group: "SOI Remastered",
    },
    {
      title: "Celebrating a Seaworthy R/V Falkor (too)",
      image: "celebrating-img.webp",
      route: "/celebrating-a-seaworthy",
      group: "SOI Remastered",
    },
    {
      title: "R/V Falkor (too)",
      image: "rvfalkor-img.webp",
      route: "/rv-falkor-too",
      group: "SOI Remastered",
    },
    {
      title: "The Future Faces of Ocean Science",
      image: "future-faces-img.webp",
      route: "/the-future-faces-of-ocean-science",
      group: "",
    },
    {
      title: "A Limitless Ocean of Data",
      image: "limitless-img.webp",
      route: "/a-limitless-ocean-of-data",
      group: "",
    },
  ];

  return (
    <>
      {props.group !== "" ? (
        <div className="subpage-recuadro related-recuadro general-text">
          <h3 className="related-group-title title">Related Content</h3>
          <div className="related-group-container">
            {lista
              .filter(
                (el) => el.group === props.group && el.title !== props.title
              )
              .map((e) => (
                <RelatedItem
                  key={e.title}
                  title={e.title}
                  image={e.image}
                  route={e.route}
                />
              ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default RelatedGroup;
